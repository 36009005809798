import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Reveal, Animation } from 'react-genie'
import { customMedia } from '../../common/customMedia'
import { Image } from '../../common/Image'
import { MyButton } from '../../common/MyButton'

export const Contact = () => {
  return (
    <>
      <Container id="contact">
        <Wrapper>
          <Reveal delay={500} animation={Animation.SlideInLeft}>
            <AboutWrapper>
              <AboutImageWrapper>
                <Image path="about@2x.png" />
              </AboutImageWrapper>
              <ButtonWrapper>
                <Link to="/company">
                  <MyButton text="Company" />
                </Link>
              </ButtonWrapper>
              <ButtonWrapper>
                <Link to="/science">
                  <MyButton text="Science" />
                </Link>
              </ButtonWrapper>
            </AboutWrapper>
          </Reveal>

          <Reveal delay={500} animation={Animation.SlideInRight}>
            <ContactWrapper>
              <ContactImageWrapper>
                <Image path="contact@2x.png" />
              </ContactImageWrapper>
              <ButtonWrapper>
                <Link to="/contact">
                  <MyButton text="Mail" />
                </Link>
              </ButtonWrapper>
              <ButtonWrapper>
                <Link to="/recruit">
                  <MyButton text="Recruit" />
                  </Link>
              </ButtonWrapper>
            </ContactWrapper>
          </Reveal>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  padding: 35px 0 20px;
`

const Wrapper = styled.div`
  ${customMedia.lessThan("large")`
  `}
  ${customMedia.greaterThan("large")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    padding: 70px 0;
    width: 900px;
  `}
`

const AboutWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const AboutImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 62px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto 70px;
    width: 123px;
  `}
`

const ButtonWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    text-align: center;
    margin: 30px 0;
  `}
  ${customMedia.greaterThan("medium")`
    text-align: center;
    margin: 45px 0;
  `}
`

const ContactWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

`

const ContactImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 75px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto 70px;
    width: 150px;
  `}
`