import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Reveal } from 'react-genie'
import { customMedia } from '../common/customMedia'
import { News } from './News'
import { RemohabImage } from './RemohabImage'
import { Member } from './Member'
import { Contact } from './Contact'
import { useTranslation, Trans } from 'react-i18next'
const SpImg = require('../../images/top_sp.jpg')
const EnSpImg = require('../../images/top_en_sp.jpg')
const PcImg = require('../../images/top_pc.jpg')
const EnPcImg = require('../../images/top_en_pc.jpg')
const JpVideo = require('../../videos/07_rimohab_mov_v6.mp4')

export const Home = () => {
  const [t, i18n] = useTranslation()
  const [spImg, setSpImg] = useState(<img src={SpImg} alt="remohab" />)
  const [pcImg, setPcImg] = useState(<img src={PcImg} alt="remohab" />)
  const videoRef = useRef<HTMLVideoElement>()

  useEffect(() => {
    i18n.language === 'ja'
      ? setSpImg(<img src={SpImg} alt="remohab" />)
      : setSpImg(<img src={EnSpImg} alt="remohab" />)
    i18n.language === 'ja'
      ? setPcImg(<img src={PcImg} alt="remohab" />)
      : setPcImg(<img src={EnPcImg} alt="remohab" />)

    videoRef.current.src = JpVideo
  }, [i18n.language])

  return (
    <>
      <SpTopImageWrapper>{spImg}</SpTopImageWrapper>
      <PcTopImageWrapper>{pcImg}</PcTopImageWrapper>
      <Reveal delay={500}>
        <VideoWrapper>
          <video ref={videoRef} controls>
            <source src={JpVideo} type="video/mp4" />
          </video>
        </VideoWrapper>
      </Reveal>
      <News />
      {/* <RemohabImage /> */}
      <Member />
      <Contact />
    </>
  )
}

const SpTopImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    display: block;
  `}
  ${customMedia.greaterThan('medium')`
    display: none;
  `}
  width: 100%;
`

const PcTopImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    display: none;
  `}
  ${customMedia.greaterThan('medium')`
    display: block;
  `}
  width: 100%;
`

const VideoWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 22px 0;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 80px 0;
  `}

  video {
    ${customMedia.lessThan('small')`
      height: 188px;
      margin: 0 auto;
      padding: 0 20px;
      width: 100%;
    `}
    ${customMedia.between('small', 'medium')`
      height: 188px;
      margin: 0 auto;
      width: 335px;
    `}
    ${customMedia.between('medium', 'large')`
      height: 281px;
      margin: 0 auto;
      width: 500px;
    `}
    ${customMedia.greaterThan('large')`
      height: 562px;
      margin: 0 auto;
      width: 1000px;
    `}

    :focus {
      outline:0;
    }
  }
  text-align: center;
`
