import { connect } from 'react-redux'
import { Member as Base, Props, Handlers } from './Member'
import { RootState } from '../../../state/types'
import {
  getMemberList,
  getAdvisoryBoardList,
  getIsListLoading
} from '../../../state/member/selectors'
import { fetchList } from '../../../state/member/operations'

const mapStateToProps = (state: RootState): Props => ({
  memberList: getMemberList(state),
  limitedAvisoryBoardList: getAdvisoryBoardList(3)(state),
  isListLoading: getIsListLoading(state)
})

const mapDispatchToProps: Handlers = {
  fetchList
}

export const Member = connect(mapStateToProps, mapDispatchToProps)(Base)
