import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Reveal } from 'react-genie'
import { customMedia } from '../../common/customMedia'
import { Member as MemberModel } from '../../../models/member'
import { parse } from 'querystring'
import { Image } from '../../common/Image'
import { MyButton } from '../../common/MyButton'
import { useTranslation, Trans } from 'react-i18next'

export interface Props {
  memberList: MemberModel[]
  limitedAvisoryBoardList: MemberModel[]
  isListLoading: boolean
}

export interface Handlers {
  fetchList: (draftKey: string) => void
}

export const Member = (props: Props & Handlers) => {
  const { memberList, limitedAvisoryBoardList } = props
  const { fetchList } = props

  const [t, i18n] = useTranslation()

  useEffect(() => {
    const query = parse(window.location.search.slice(1))
    fetchList(query.draftKey as string)
  }, [])

  return (
    <>
      <Container id="member">
        <Wrapper>
          <MemberWrapper>
            <Reveal delay={500}>
              <MemberImageWrapper>
                <Image path="member@2x.png" />
              </MemberImageWrapper>
            </Reveal>

            <MemberListWrapper>
              {memberList.map((m, i) => (
                <MemberCardWrapper key={i}>
                  <Link to={`/member#${m.id}`}>
                    <Reveal delay={500}>
                      <MemberCard>
                        <ImgWrapper>
                          <Img src={m.image.url} alt={m.name} />
                          <Mask />
                        </ImgWrapper>
                        <Name lang={i18n.language}>{i18n.language === 'ja' ? m.name : m.enName}</Name>
                        <EnName>{i18n.language === 'ja' && m.enName}</EnName>
                        <Title>{i18n.language === 'ja' ? m.title : m.enTitle.split('\n').map((m, i) => <span key={i}>{m}<br/></span>)}</Title>
                      </MemberCard>
                    </Reveal>
                  </Link>
                </MemberCardWrapper>
              ))}
            </MemberListWrapper>      
          </MemberWrapper>
      
          <MemberWrapper>
            <Reveal delay={500}>
              <AdvisoryBoardImageWrapper>
                <Image path="advisory_board@2x.png" />
              </AdvisoryBoardImageWrapper>
            </Reveal>

            <MemberListWrapper>
              {limitedAvisoryBoardList.map((m, i) => (
                <MemberCardWrapper key={i}>
                  <Link to={`/member#${m.id}`}>
                    <Reveal delay={500}>
                      <MemberCard>
                        <ImgWrapper>
                          <Img src={m.image.url} alt={m.name} />
                          <Mask />
                        </ImgWrapper>
                        <Name lang={i18n.language}>{i18n.language === 'ja' ? m.name : m.enName}</Name>
                        <EnName>{i18n.language === 'ja' && m.enName}</EnName>
                        {i18n.language ==='ja' ? <Title>エグゼクティブ<br/>アドバイザー</Title> : <Title>Executive Advisor</Title>}
                      </MemberCard>
                    </Reveal>
                  </Link>
                </MemberCardWrapper>
              ))}
            </MemberListWrapper>
          </MemberWrapper>
          
          <Reveal delay={500}>
            <ButtonWrapper>
              <Link to="/member">
                <MyButton text={`${t('list')} ＞`} />
              </Link>
            </ButtonWrapper>
          </Reveal>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 45px 0;
  `}
  ${customMedia.greaterThan("medium")`
    padding: 75px 0;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 735px;
  `}
`

const MemberWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const MemberImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-bottom: 30px;
    width: 80px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-bottom: 70px;
    width: 160px;
  `}

  margin: 0 auto;
`

const AdvisoryBoardImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 30px auto;
    width: 150px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 60px auto;
    width: 300px;
  `}

`

const MemberListWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
`

const MemberCardWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-bottom: 25px;
    width: 50%;
  `}
  ${customMedia.greaterThan("medium")`
    margin-bottom: 40px;
    width: calc(100% / 3);
  `}
`

const ImgWrapper = styled.div`
  flex: none;
  position: relative;

  :hover {
    div {
      display: block;
    }
  }
`

const Img = styled.img`
`

const Mask = styled.div`
  background-color: rgba(38, 210, 173, .5);
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const MemberCard = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 128px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 200px;
  `}

  cursor: pointer;
`

const Name = styled.div<{lang: string}>`
  ${props => customMedia.lessThan("medium")`
    font-size: ${props.lang === 'ja' ? '1.5rem' : '1.2rem'};
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
    letter-spacing: -0.5px;
    margin-top: 18px;
  `}


  color: #26d2ad;
  font-weight: bold;
`

const EnName = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
  `}

  color: #60656b;
`

const Title = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.15rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    letter-spacing: -0.5px;
  `}

  color: #60656b;
  font-weight: bold;
`

const ButtonWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 30px;
  `}

  text-align: center;
`
