import { News } from '../../models/news'
import {
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  FetchListActionTypes
} from './types'

const fetchListRequest = (): FetchListActionTypes => ({
  type: FETCH_LIST_REQUEST
})

const fetchListSuccess = (list: News[]): FetchListActionTypes => ({
  type: FETCH_LIST_SUCCESS,
  payload: { list }
})

const fetchListFailure = (): FetchListActionTypes => ({
  type: FETCH_LIST_FAILURE
})

export default {
  fetchListRequest,
  fetchListSuccess,
  fetchListFailure
}
