import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Reveal } from 'react-genie'
import { customMedia } from '../../common/customMedia'
import { News as NewsModel } from '../../../models/news'
import { parse } from 'querystring'
import { Image } from '../../common/Image'
import { MyButton } from '../../common/MyButton'
import { useTranslation, Trans } from 'react-i18next'

export interface Props {
  list: NewsModel[]
  isLoading: boolean
}

export interface Handlers {
  fetchList: (params: { [key: string]: string | number }) => void
}

export const News = (props: Props & Handlers) => {
  const { list } = props
  const { fetchList } = props

  const [t, i18n] = useTranslation()

  useEffect(() => {
    const query = parse(window.location.search.slice(1))
    if (list.length === 0) {
      fetchList({ limit: 4, draftKey: query.draftKey as string })
    }
  }, [])

  return (
    <>
      <Container id="news">
        <Wrapper>
          <Reveal delay={500}>
            <NewsIconWrapper>
              <Image path="news@2x.png" />
            </NewsIconWrapper>
          </Reveal>
          <NewsListWrapper>
            {list.map((n, i) => i < 4 ?
              <NewsCardWrapper key={i}>
                <Link to={`/news#${n.id}`}>
                  <Reveal delay={500}>
                    <NewsCard>
                      <ImgWrapper>
                        <Img src={n.image.url} alt={n.content} />
                        <Mask />
                      </ImgWrapper>
                      <ContentWrapper>
                        <Metadata>
                          <Category>
                            {n.category.title}
                          </Category>
                          <DateText>
                            {n.date}
                          </DateText>
                        </Metadata>
                        <Content>
                          {i18n.language === 'ja' ? n.content.length <= 50 ? n.content : n.content.substr(0, 50) + '..' 
                            : n.enContent.length <= 80 ? n.enContent : n.enContent.substr(0, 80) + '..'}
                        </Content>
                        <TextLink>
                          {t('readMore')} ＞
                        </TextLink>
                      </ContentWrapper>
                    </NewsCard>
                  </Reveal>
                </Link>
              </NewsCardWrapper>
            : '')}
          </NewsListWrapper>
          
          <Reveal delay={500}>
            <ButtonWrapper>
              <Link to="/news">
                <MyButton text={`${t('list')} ＞`} />
              </Link>
            </ButtonWrapper>
          </Reveal>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    padding: 35px 0;
  `}
  ${customMedia.between("medium", "large")`
    padding: 75px 20px;
  `}
  ${customMedia.greaterThan("large")`
    margin: 0 auto;
    padding: 75px 0;
    width: 1000px;
  `}
`

const NewsIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto 35px;
    width: 50px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto 70px;
    width: 100px;
  `}

`

const NewsListWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  `}
`

const NewsCardWrapper = styled.div`
  ${customMedia.lessThan("large")`
    margin-bottom: 30px;
  `}
  ${customMedia.greaterThan("large")`
    width: 50%;
    margin-bottom: 70px;
  `}
`

const NewsCard = styled.div`
  ${customMedia.lessThan("large")`
    height: 100px;
  `}
  ${customMedia.greaterThan("large")`
    height: 200px;
  `}

  display: -webkit-flex;
  display: flex;

  :nth-child(odd) {
    padding-right: 25px;
  }
`

const ImgWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    width: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 200px;
  `}

  flex: none;
  position: relative;

  :hover {
    div {
      display: block;
    }
  }
`

const Img = styled.img`
`

const Mask = styled.div`
  background-color: rgba(38, 210, 173, .5);
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const ContentWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 13px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-left: 25px;
  `}

  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
`

const Metadata = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
`

const Category = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 0.7rem;
    margin-right: 9px;
    padding: 0 5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.4rem;
    margin-right: 18px;
    padding: 0 10px;
  `}

  color: #fff;
  font-weight: bold;
  background-color: #26d2ad;
`

const DateText = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
    line-height: 30px;
  `}

  color: #26d2ad;
  font-weight: bold;
`

const Content = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.1rem;
    line-height: 14px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    line-height: 30px;
  `}

  color: #60656b;
  margin-top: 8px; 
`
const TextLink = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
  `}

  color: #26d2ad;
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  bottom: 0;
`

const ButtonWrapper = styled.div`
  text-align: center;
`
