import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { TopSEO } from '../components/common/TopSEO'

import { Home } from '../components/Home'

const IndexPage = () => (
  <Layout>
    <TopSEO
      title='遠隔医療をデザインする会社'
      description='Remohab（リモハブ）は、世界の死亡原因第1位である心疾患の遠隔医療をサポートする会社です。オンライン心臓リハビリシステムで、医療機関とご自宅をクラウドで繋ぎ、リハビリを継続しやすい環境を作り、患者様とご家族、より多くの人を「健幸」へと導いていきます。'
      keywords={['心疾患','リハビリ','リモート','遠隔診療','健康寿命','リモハブ','remohab']}
    />  
    <H1>心疾患　心不全　在宅　リハビリ　遠隔診療 | Remohab リモハブ</H1>
    <Home />
  </Layout>
)

const H1 = styled.h1`
  font-size: 1.8rem;
`

export default IndexPage
