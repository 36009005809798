import actions from './actions'
import { getNewsList } from '../../lib/httpService/api'

export const fetchList = (params?: { [key: string]: string | number }) => async (dispatch: any) => {
  dispatch(actions.fetchListRequest)
  
  try {
    const list = await getNewsList(params)
    dispatch(actions.fetchListSuccess(list))
  } catch (err) {
    console.log(err)
    dispatch(actions.fetchListFailure)
  }
}