import { createSelector } from 'reselect'
import { News } from '../../models/news'
import { RootState } from '../types'
import { State } from './types'

const newsSelector = (state: RootState): State => state.news

export const getList = createSelector(
  newsSelector,
  (state: State): News[] => state.list
)

export const getIsLoading = createSelector(
  newsSelector,
  (state: State): boolean => state.isLoading
)

export const selectors = {
  getList,
  getIsLoading
}
